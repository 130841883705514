document.addEventListener("turbolinks:load", function(){
  var month_trend_chart = document.getElementById('month_trend_chart');
  if(typeof(month_trend_chart) != 'undefined' && month_trend_chart != null) {
    var month_trend_array = JSON.parse(month_trend_chart.dataset.searches);
    var month_trend_data = month_trend_array.map(function(e) {
      return e;
    });
    var options = {
      colors: ["#07818F"],
      grid: {
        borderColor:'#edebeb',
        row: {
          colors: ['#f5f6fa', 'transparent'],
          opacity: 0.5
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      chart: {
        animations: {
          enabled: false
        },
        markers: {
          size: 4
        },
        type: 'area',
        height: 300,
        zoom: {
          enabled: false
        },
        sparkline: {
          enabled: true
        },
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '100%'
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 4
      },
      series: [{
        name: 'Searches',
        data: month_trend_data
      }],
      xaxis: {
        type: 'category',
        axisTicks: {
          show: false
        }
      },
      legend: {
        horizontalAlign: 'center',
        offsetY: -5
      }
    }

    var chart = new ApexCharts(document.querySelector("#month_trend_chart"), options);
    chart.render();
  }
});
